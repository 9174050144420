import React, { Component } from "react";

import Video from "../layouts/aboutplayer.js";
import { Player, ControlBar } from "video-react";
import fsc from "../img/icons/logo-fsc.png";
import quotes from "../img/icons/quotes.svg";
import quotesWhite from "../img/icons/quotes_white.png";
import playArrow from "../img/playarrow.svg";
import { Helmet } from "react-helmet";
import DefaultLayout from "../layouts/defaultLayout";
import forte from "../img/forte_logo.png";

import "../styles/about.scss";
const language = {
  "nl-be": 0,
  "en-gb": 2,
  "fr-be": 1,
};

export default class Approach extends Component {
  constructor(props) {
    super(props);

    /*var path = this.props.location.pathname.indexOf("/")
        var lang = this.props.location.pathname.substr(path+1,5)

        var data ;
        console.log(this)
        for(var i = 0;i<this.props.data.allPrismicDocument.edges.length;i++){
            if(this.props.data.allPrismicDocument.edges[i].node.lang == lang){
                data = this.props.data.allPrismicDocument.edges[i];
            }
        }*/

    var data;
    data = this.props.data.allPrismicAbout.edges[0];

    this.state = {
      data,
    };

    // console.log(this)
  }

  render() {
    const data = this.props.data.allPrismicAbout.edges[0].node.data;
    console.log("approach", data);

    var aanpakk1 = data.body[0].items.map((item, i) => (
      <div key={item.naam.text} className="itemContainer">
        <div
          style={{ backgroundImage: "url(" + item.img.url + ")" }}
          className="itemImage"
        ></div>
        <div className="itemText">
          <div className="title">{item.naam.text}</div>
          <div className="text">{item.quote.text}</div>
        </div>
      </div>
    ));

    var aanpakk2 = data.body[1].items.map((item) => (
      <div key={item.naam.text} className="itemContainer">
        <div
          style={{ backgroundImage: "url(" + item.img.url + ")" }}
          className="itemImage"
        ></div>
        <div className="itemText">
          <div className="title">{item.naam.text}</div>
          <div className="text">{item.quote.text}</div>
        </div>
      </div>
    ));

    var aanpakMobile = [];
    for (var i = 0; i < aanpakk1.length; i++) {
      aanpakMobile.push(aanpakk1[i]);
      aanpakMobile.push(aanpakk2[i]);
    }

    /* var transport = this.state.transport.items.map(item =>
            <div key={item.text[0].text} className="itemContainer">
                <div style={{backgroundImage: "url(" + item.image.url + ")"}} className="itemImage">

                </div>
                <div className="text">
                    {item.text[0].text}
                </div>
            </div>
        )*/
    return (
      <DefaultLayout data={this.props.data.allPrismicMenu.edges[0].node.data}>
        <Helmet>
          <title>{data.meta_title.text}</title>
          <meta name="description" content={data.meta_description.text} />
        </Helmet>
        <div
          id="aboutHeaderImage"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(" +
              data.header_image.url +
              ")",
          }}
        >
          <div id="titleContainer">
            <h1>Forté</h1>
            <h2>{data.titel.text}</h2>
          </div>
        </div>
        <div id="aboutBodyContainer">
          <div id="aanpakContainer">
            <div className="aanpakRow">
              <h2>{data.team_title.text}</h2>
              <div className="subTitleContainer">
                <div className="horizontalStripe"></div>
              </div>
              {aanpakk1}
            </div>
            <div className="aanpakRow">{aanpakk2}</div>
          </div>

          <div id="aanpakContainerMobile">
            <div className="aanpakRow">
              <h2>{data.team_title.text}</h2>
              <div className="subTitleContainer">
                <div className="horizontalStripe"></div>
              </div>
              {aanpakMobile}
            </div>
          </div>
          <section id="about" className="jobs">
            <div className="aboutContainer">
              <div className="overflow">
                <img src={data.image_jobs.url} />
              </div>
              <div id="jobs" className="AboutTextContainer">
                <div className="horizontalLine"></div>
                <div className="title">
                  <h1>{data.jobs_titel.text}</h1>
                </div>
                <div className="text">
                  {data.jobs_text.text}
                  <div className="storyLink">
                    <a href={"/contact"}> {data.jobs_link_text.text} ></a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="transportContainer">
            <h2>{data.titel.text}</h2>
            <div className="subTitleContainer">
              <div className="horizontalStripe"></div>
              <div className="text">{data.over_ons_text.text}</div>
            </div>
            <div className="clear items"></div>
          </div>
        </div>
        <div className="clear"></div>
        <footer>
          <div className="footerMenuContainer">
            <div className="menuLeft">
              <div id="logoContainer">
                <img className="logo" src={forte} />
              </div>
              <div className="menuContainer">
                <ul className="menuLinks">
                  <li>
                    <a href="/specialisaties">Specialisaties</a>
                  </li>
                  <li>
                    <a href="/over-ons">Over ons</a>
                  </li>
                  <li>
                    <a href="/over-ons#jobs">Jobs</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menuRight"></div>
          </div>
          <div className="copyright">
            <div id="gafaslink">
              Website by
              <a href="https://www.gafas.be"> Gafas</a>
            </div>
          </div>
        </footer>
      </DefaultLayout>
    );
  }
}

export const pageQuery = graphql`
  query about {
    allPrismicMenu {
      edges {
        node {
          id
          slugs
          lang
          data {
            logo {
              url
            }
            telefoonnummer {
              html
              text
            }
            contact {
              html
              text
            }
            diensten {
              html
              text
            }
            over_ons {
              html
              text
            }
            jobs {
              html
              text
            }
            afspraak {
              html
              text
            }
            body {
              ... on PrismicMenuBodyDiensten {
                items {
                  titel {
                    html
                    text
                  }
                  link {
                    html
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicAbout {
      edges {
        node {
          id
          data {
            titel {
              html
              text
            }
            meta_title {
              html
              text
            }
            meta_description {
              html
              text
            }
            team_title {
              html
              text
            }
            over_ons_text {
              html
              text
            }
            jobs_titel {
              html
              text
            }
            jobs_text {
              html
              text
            }
            jobs_link_text {
              html
              text
            }
            header_image {
              url
            }
            image_jobs {
              url
            }
            body {
              ... on PrismicAboutBodyKinesist {
                slice_type
                items {
                  img {
                    url
                  }
                  naam {
                    html
                    text
                  }
                  quote {
                    html
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
