//import "../../css/video.css"; // or import scss
import React, { Component } from 'react';
import { Player, ControlBar, VolumeMenuButton, BigPlayButton, PlayToggle } from 'video-react';
import bg from '../img/bgmartalvideo.png'



export default class Video extends Component {
    constructor(props) {
        super(props);
      }
    render() {
      return (
        <div id="videocontainer">
                <Player ref="player" poster={'https://prismic-io.s3.amazonaws.com/martal-test%2F52c0e7ab-baa5-41d6-afa7-570f1d1b4a04_martal+quality.jpg'} autoPlay={false} loop={false}>
                <source src={'https://prismic-io.s3.amazonaws.com/martal-test%2F8a82f2d6-dfc1-4dbd-9858-02c8a4233df4_martal_v02_met+tekst_finaal.webm'}/>
                <source src={'https://prismic-io.s3.amazonaws.com/martal-test%2Fcc134f29-96ef-45e8-9846-9270128a1d82_martal_v02_met+tekst_finaal_1.mp4'}/>
                <ControlBar autoHide={false} disableDefaultControls={true}>
        <PlayToggle />
      </ControlBar>
            </Player>
        </div>
      );
    }
  }
